@mixin row($reverse: false, $vCenter: false, $hCenter: false) {
  display: flex;

  @if ($reverse==true) {
    flex-direction: row-reverse;
  }

  @if ($vCenter==true) {
    align-items: center;
  }

  @if ($hCenter==true) {
    justify-content: center;
  }
}

@mixin column($reverse: false, $vCenter: false, $hCenter: false) {
  display: flex;

  @if ($reverse==true) {
    flex-direction: column-reverse;
  } @else {
    flex-direction: column;
  }

  @if ($hCenter==true) {
    align-items: center;
  }

  @if ($vCenter==true) {
    justify-content: center;
  }
}

@mixin wrap($reverse: false) {
  @if ($reverse==true) {
    flex-wrap: wrap-reverse;
  } @else {
    flex-wrap: wrap;
  }
}
